@font-face {
  font-family: 'ProductSans-Bold';
  src: local('ProductSans-Bold'), url(./fonts/ProductSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'ProductSans-Medium';
  src: local('ProductSans-Medium'), url(./fonts/ProductSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'ProductSans-Thin';
  src: local('ProductSans-Thin'), url(./fonts/ProductSans-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'ProductSans-Regular';
  src: local('ProductSans-Regular'), url(./fonts/ProductSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ProductSans-Light';
  src: local('ProductSans-Light'), url(./fonts/ProductSans-Light.ttf) format('truetype');
}

* {
  font-family: 'ProductSans-Medium', serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-transform: translate3d(0, 0, 0);
  color: #5E6369;
  font-weight: 100;
  background-color: #F3F1E5;
}


body {
  margin-top: 0.5vmin;
  /* scroll-behavior: smooth; */
  /* background-color: #131a2a !important;
  margin-bottom: 1vmax !important; */
}
